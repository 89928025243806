<template>
  <div>
    <CRow class="justify-content-center mt-3" >
      <CCol sm="12" lg="auto">
        <b>
          <label class="col-form-label-sm col-sm-12 col-lg-12 text-right px-1">{{$t('label.customBroker')}}</label>
        </b>
      </CCol>
      <CCol sm="12" lg="8"></CCol>
    </CRow>

    <CRow class="justify-content-center mt-3">
      <CCol sm="12" lg="5">
        <CSelect
          size="sm"
          :label="$t('label.customBroker')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          :placeholder="''"
          addLabelClasses="text-right"
          v-model.trim="vForm.customBroke.CustomBrokerId.$model"
          :is-valid="hasError(vForm.customBroke.CustomBrokerId)"
          :invalid-feedback="errorMessage(vForm.customBroke.CustomBrokerId)"
          class="remove-space"
          :options="customBrokerOptions"
          :value.sync="form.customBroke.CustomBrokerId"
          @change="selectCustomBroker($event)"
        >
        </CSelect>
      </CCol>
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.rifNumber')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.customBroke.CustomBrokerRif.$model"
          :is-valid="hasError(vForm.customBroke.CustomBrokerRif)"
          :invalid-feedback="errorMessage(vForm.customBroke.CustomBrokerRif)"
          disabled
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="5"></CCol>
    </CRow>

    <CRow class="justify-content-center">
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.name')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.customBroke.CustomBrokerName.$model"
          :is-valid="hasError(vForm.customBroke.CustomBrokerName)"
          :invalid-feedback="errorMessage(vForm.customBroke.CustomBrokerName)"
          disabled
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.address')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.customBroke.CustomBrokerAddress.$model"
          :is-valid="hasError(vForm.customBroke.CustomBrokerAddress)"
          :invalid-feedback="errorMessage(vForm.customBroke.CustomBrokerAddress)"
          disabled
        >
        </CInput>
      </CCol>
    </CRow>

    <CRow class="justify-content-center">
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.mainPhone')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.customBroke.CustomBrokerPrimaryPhone.$model"
          :is-valid="hasError(vForm.customBroke.CustomBrokerPrimaryPhone)"
          :invalid-feedback="errorMessage(vForm.customBroke.CustomBrokerPrimaryPhone)"
          disabled
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="5" >
        <CInput
          size="sm"
          :label="$t('label.secondaryPhone')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.customBroke.CustomBrokerSecondaryPhone.$model"
          :is-valid="hasError(vForm.customBroke.CustomBrokerSecondaryPhone)"
          :invalid-feedback="errorMessage(vForm.customBroke.CustomBrokerSecondaryPhone)"
          disabled
        >
        </CInput>
      </CCol>
    </CRow>

    <CRow class="justify-content-center">
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.mainEmail')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.customBroke.CustomBrokerPrimaryEmail.$model"
          :is-valid="hasError(vForm.customBroke.CustomBrokerPrimaryEmail)"
          :invalid-feedback="errorMessage(vForm.customBroke.CustomBrokerPrimaryEmail)"
          disabled
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.secondaryEmail')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.customBroke.CustomBrokerSecondaryEmail.$model"
          :is-valid="hasError(vForm.customBroke.CustomBrokerSecondaryEmail)"
          :invalid-feedback="errorMessage(vForm.customBroke.CustomBrokerSecondaryEmail)"
          disabled
        >
        </CInput>
      </CCol>
    </CRow>

    <CRow class="justify-content-center" >
      <CCol sm="12" lg="auto">
          <label class="col-form-label-sm col-sm-12 col-lg-12 text-right px-1">{{$t('label.isThisTheConsolidator')}}</label>
      </CCol>
      <CCol sm="12" lg="8">
        <div class="form-group row">
          <label class="col-sm-6 col-lg-auto text-right">NO</label>
          <div class="col-sm-6 col-lg-auto d-flex align-items-center px-0">
            <CSwitch
              color="watch"
              variant="3d"
              type="checkbox"
              :checked.sync="vForm.customBroke.consolidatorCheck.$model"
              @change.native="(event)=> changeConsolidator(event)"
            />
          </div>
          <label class="col-sm-6 col-lg-auto text-right">{{ `${this.$t('label.yes')}`}}</label>
        </div>
      </CCol>
    </CRow>

    <CRow class="justify-content-center mt-3" v-show="!this.form.customBroke.consolidatorCheck">
      <CCol sm="12" lg="auto">
        <b>
          <label class="col-form-label-sm col-sm-12 col-lg-12 text-right px-1">{{$t('label.consolidator')}}</label>
        </b>
      </CCol>
      <CCol sm="12" lg="8"></CCol>
    </CRow>

    <CRow class="justify-content-center mt-3" v-show="!this.form.customBroke.consolidatorCheck">
      <CCol sm="12" lg="5">
        <CSelect
          size="sm"
          :label="$t('label.consolidator')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          :placeholder="''"
          addLabelClasses="text-right"
          v-model.trim="vForm.consolidator.ConsolidatorId.$model"
          :is-valid="hasError(vForm.consolidator.ConsolidatorId)"
          :invalid-feedback="errorMessage(vForm.consolidator.ConsolidatorId)"
          class="remove-space"
          :options="consolidatorOptions"
          :value.sync="form.consolidator.ConsolidatorId"
          @change="selectConsolidator($event)"
        >
        </CSelect>
      </CCol>
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.rifNumber')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.consolidator.ConsolidatorRif.$model"
          :is-valid="hasError(vForm.consolidator.ConsolidatorRif)"
          :invalid-feedback="errorMessage(vForm.consolidator.ConsolidatorRif)"
          disabled
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="5"></CCol>
    </CRow>

    <CRow class="justify-content-center" v-show="!this.form.customBroke.consolidatorCheck">
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.name')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.consolidator.ConsolidatorName.$model"
          :is-valid="hasError(vForm.consolidator.ConsolidatorName)"
          :invalid-feedback="errorMessage(vForm.consolidator.ConsolidatorName)"
          disabled
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.address')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.consolidator.ConsolidatorAddress.$model"
          :is-valid="hasError(vForm.consolidator.ConsolidatorAddress)"
          :invalid-feedback="errorMessage(vForm.consolidator.ConsolidatorAddress)"
          disabled
        >
        </CInput>
      </CCol>
    </CRow>

    <CRow class="justify-content-center" v-show="!this.form.customBroke.consolidatorCheck">
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.mainPhone')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.consolidator.ConsolidatorPrimaryPhone.$model"
          :is-valid="hasError(vForm.consolidator.ConsolidatorPrimaryPhone)"
          :invalid-feedback="errorMessage(vForm.consolidator.ConsolidatorPrimaryPhone)"
          disabled
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="5" >
        <CInput
          size="sm"
          :label="$t('label.secondaryPhone')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.consolidator.ConsolidatorSecondaryPhone.$model"
          :is-valid="hasError(vForm.consolidator.ConsolidatorSecondaryPhone)"
          :invalid-feedback="errorMessage(vForm.consolidator.ConsolidatorSecondaryPhone)"
          disabled
        >
        </CInput>
      </CCol>
    </CRow>

    <CRow class="justify-content-center" v-show="!this.form.customBroke.consolidatorCheck">
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.mainEmail')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.consolidator.ConsolidatorPrimaryEmail.$model"
          :is-valid="hasError(vForm.consolidator.ConsolidatorPrimaryEmail)"
          :invalid-feedback="errorMessage(vForm.consolidator.ConsolidatorPrimaryEmail)"
          disabled
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.secondaryEmail')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.consolidator.ConsolidatorSecondaryEmail.$model"
          :is-valid="hasError(vForm.consolidator.ConsolidatorSecondaryEmail)"
          :invalid-feedback="errorMessage(vForm.consolidator.ConsolidatorSecondaryEmail)"
          disabled
        >
        </CInput>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import { mapState } from 'vuex';
import { Money } from "v-money";

function data() {
  return {
    CustomBrokerItems: [],
    consolidatorItems: []
  };
}

//-----------------------   Method    ------------------------

function selectCustomBroker(event) {
  this.form.customBroke.CustomBrokerId = event.target.value;
  if (this.form.customBroke.CustomBrokerId) {
    let data = this.CustomBrokerItems.find((item) => item.ClientTpId == this.form.customBroke.CustomBrokerId);

    //llenado de campos según el agente aduanal
    this.form.customBroke.CustomBrokerRif = data?.ClientRif ?? '';
    this.form.customBroke.CustomBrokerName =  data?.ClientName ?? '';
    this.form.customBroke.CustomBrokerAddress = data?.ClientAddress ?? '';
    this.form.customBroke.CustomBrokerPrimaryPhone = data?.PrimaryPhone ?? '';
    this.form.customBroke.CustomBrokerSecondaryPhone = data?.SecondaryPhone ?? '';
    this.form.customBroke.CustomBrokerPrimaryEmail = data?.PrimaryEmail ?? '';
    this.form.customBroke.CustomBrokerSecondaryEmail = data?.SecondaryEmail ?? '';

    this.vForm.customBroke.$touch();
  }else{
     //reseteo de los campos
    this.form.customBroke.CustomBrokerRif =  '';
    this.form.customBroke.CustomBrokerName =  '';
    this.form.customBroke.CustomBrokerAddress = '';
    this.form.customBroke.CustomBrokerPrimaryPhone = '';
    this.form.customBroke.CustomBrokerSecondaryPhone = '';
    this.form.customBroke.CustomBrokerPrimaryEmail = '';
    this.form.customBroke.CustomBrokerSecondaryEmail = '';
  }
}

function selectConsolidator(event) {
  this.form.consolidator.ConsolidatorId = event.target.value;
  if (this.form.consolidator.ConsolidatorId) {
    let data = this.consolidatorItems.find((item) => item.ClientTpId == this.form.consolidator.ConsolidatorId);

    //consolidator
    this.form.consolidator.ConsolidatorRif = data?.ClientRif ?? '';
    this.form.consolidator.ConsolidatorName = data?.ClientName ?? '';
    this.form.consolidator.ConsolidatorAddress =  data?.ClientAddress ?? '';
    this.form.consolidator.ConsolidatorPrimaryPhone =  data?.PrimaryPhone ?? '';
    this.form.consolidator.ConsolidatorSecondaryPhone = data?.SecondaryPhone ?? '';
    this.form.consolidator.ConsolidatorPrimaryEmail = data?.PrimaryEmail ?? '';
    this.form.consolidator.ConsolidatorSecondaryEmail = data?.SecondaryEmail ?? '';

    this.vForm.consolidator.$touch();
  }else{
    //reseteo de los campos
    this.form.consolidator.ConsolidatorRif =  '';
    this.form.consolidator.ConsolidatorName ='';
    this.form.consolidator.ConsolidatorAddress =  '';
    this.form.consolidator.ConsolidatorPrimaryPhone =  '';
    this.form.consolidator.ConsolidatorSecondaryPhone = '';
    this.form.consolidator.ConsolidatorPrimaryEmail = '';
    this.form.consolidator.ConsolidatorSecondaryEmail = '';
  }
}

async function resetForm() {

  //customBroke
  this.form.customBroke.CustomBrokerId =  '';
  this.form.customBroke.CustomBrokerRif = '';
  this.form.customBroke.CustomBrokerName = '';
  this.form.customBroke.CustomBrokerAddress = '';
  this.form.customBroke.CustomBrokerPrimaryPhone = '';
  this.form.customBroke.CustomBrokerSecondaryPhone = '';
  this.form.customBroke.CustomBrokerPrimaryEmail = '';
  this.form.customBroke.CustomBrokerSecondaryEmail = '';

  //data array
 
  this.CustomBrokerItems = [];
  this.consolidatorItems = [];
}

function changeConsolidator() {
  if (!this.form.customBroke.consolidatorCheck) {
    this.getListApisCustomBroker();
  }else{
    this.form.consolidator.ConsolidatorId = '';
    this.form.consolidator.ConsolidatorRif ='';
    this.form.consolidator.ConsolidatorName = '';
    this.form.consolidator.ConsolidatorAddress = '';
    this.form.consolidator.ConsolidatorPrimaryPhone = '';
    this.form.consolidator.ConsolidatorSecondaryPhone = '';
    this.form.consolidator.ConsolidatorPrimaryEmail = '';
    this.form.consolidator.ConsolidatorSecondaryEmail = '';
    this.consolidatorItems = [];
  }
}


async function getListApisCustomBroker() {
  this.$store.state.yardManagement.loading = true;
  let peticiones =  
    [
      this.$http.ejecutar("GET", "ClientCustomBroker-list", {CompanyBranchId: this.branch ? this.branch.CompanyBranchId : ""}),
      this.$http.ejecutar("GET", "ClientConsolidator-list", {CompanyBranchId: this.branch ? this.branch.CompanyBranchId : ""}),
    ];
  Promise.all(peticiones)
    .then((responses) => {
      this.CustomBrokerItems = responses[0].data.data;
      this.consolidatorItems = responses[1].data.data;
      this.$store.state.yardManagement.loading = false;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
}


//-----------------------   Computed   ------------------------

function customBrokerOptions() {
  if(this.CustomBrokerItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.CustomBrokerItems.map(function(e){
      chart.push({
        value: e.ClientTpId, 
        label: e.ClientName,
      })    
    })
    return chart;
  }
}

function consolidatorOptions() {
  if(this.consolidatorItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.consolidatorItems.map(function(e){
      chart.push({
        value: e.ClientTpId, 
        label: e.ClientName,
      })    
    })
    return chart;
  }
}

export default {
  name: 'customsBroker-tab',
  components: {
      Money,
  },
  data,
  props: {
    form: {
      type: Object,
      required: true,
      default: () => {},
    },
    vForm: Object,
    isEdit: Boolean,
    validCargoMaster: Boolean,
    Tab: Boolean,
    showModal: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  methods: {
    selectCustomBroker,
    selectConsolidator,
    changeConsolidator,
    getListApisCustomBroker,
    resetForm
  },
  computed:{
    customBrokerOptions,
    consolidatorOptions,
    ...mapState({
      branch: (state) => state.auth.branch,
    })
  },
  watch: {
    showModal: async function (Newval) {
      if (!Newval) {
        this.resetForm();
      }
    },
    Tab: async function (Newval) {
      if (Newval) 
        await this.getListApisCustomBroker();
    },
  }
};
</script>