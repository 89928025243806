<template>
  <div>
    <CRow class="justify-content-center mt-3" >
      <CCol sm="12" lg="5">
        <CSelect
          horizontal
          size="sm"
          :label="$t('label.status')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          addLabelClasses="required text-right"
          class="remove-space"
          :options="StatusListOptions"
          v-model.trim="$v.form.status.Status.$model"
          :value.sync="form.status.Status"
          :is-valid="hasError($v.form.status.Status)"
          :invalid-feedback="errorMessage($v.form.status.Status)"
        />
      </CCol>
      <CCol sm="12" lg="5">
        <div class="form-group form-row d-flex aling-items-center mb-0">
          <label class="col-sm-12 col-lg-4 text-right mb-2 mt-1 required">{{`${$t('label.statusDate')}`}}</label>
          <div class="input-group col-sm-12 col-lg-8 mb-2">
            <vue-datepicker 
              type="date"
              header
              :lang="this.$i18n.locale"
              :editable="false"
              :clearable="false"
              format="DD/MM/YYYY"
              placeholder="DD/MM/YYYY"
              time-title-format="DD/MM/YYYY"
              v-model.trim="$v.form.status.TransactionDate.$model"
              :disabled-date="validateDateRange"
              :append-to-body="false"
              value-type="format"
              :show-second="false"
            >
              <template #input>
                  <CInput
                    v-uppercase
                    class="w-100 float-left"
                    v-model="$v.form.status.TransactionDate.$model"
                    placeholder="DD/MM/YYYY"
                    :is-valid="hasError($v.form.status.TransactionDate)"
                    @blur="$v.form.status.TransactionDate.$touch()"
                    size="sm"                      
                  >
                    <template #append-content>
                      <CIcon name="cil-calendar" />
                    </template>
                  </CInput>
              </template>
              <template #icon-calendar>
                  <div style="display: none"></div>
              </template>
            </vue-datepicker>
          </div>
        </div>
        
      </CCol>
    </CRow>

    <CRow class="justify-content-center mt-3" >
      <CCol sm="12" lg="5">
        <CTextarea
          :label="$t('label.observation')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          maxlength="250"
          size="sm"
          v-uppercase
          v-model.trim="$v.form.status.Motive.$model"
          addLabelClasses="text-right"
          class="remove-space"
          rows="2"
          :is-valid="hasError($v.form.status.Motive)"
          :invalid-feedback="errorMessage($v.form.status.Motive)"
        />
      </CCol>
      <CCol sm="12" lg="5">
        <CCol class="col-form-label col-sm-12 col-lg-4 col-form-label-sm text-right ml-2" style="text-align: left;">
          <CButton
              color="add"
              size="sm"
              class="mr-1"
              v-c-tooltip="{content: $t('label.add'), placement: 'top-end'}" 
              :disabled="isSubmitStatus"
              @click="submitBodyStatus()"
              >
              <CIcon name="checkAlt"/>
          </CButton>
          <CButton
              color="wipe"
              class="justify-content-end"
              size="sm"
              v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
              @click="clearFilters()"
              >
              <CIcon name="cil-brush-alt" />
          </CButton>
        </CCol>
      </CCol>
    </CRow>

    <CRow class="justify-content-center m-0 mt-3">

      <CCol sm="12">
        <dataTableExtended
          class="align-center-row-datatable"
          :items="computedStatusList"
          :fields="statusfields"
          :items-per-page="5"
          column-filter
          pagination
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
          sorter
        >
          <template #TransactionDate="{ item }">
            <td class="text-center">
              {{ getFormatterDateInList(item.TransactionDate) }}
            </td>
          </template>
          <template #Status="{ item }">
            <td class="text-center">
              <CBadge :color="getBadge(item.Status)">
                {{ $t('label.'+item.Status) }}
              </CBadge>
            </td>
          </template>
          <template #options="{ item }">
            <td class="text-center">
              <CButton 
                color="edit"
                square
                size="sm"
                class="mr-1"
                v-c-tooltip="{
                  content: $t('label.edit')+' '+$t('label.status'),
                  placement: 'top-end'
                }"
                @click="EditStatus(item)"
              >
                <CIcon name="pencil" />
              </CButton>
            </td>
          </template>
        </dataTableExtended>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import ModalBlFormValidations from '@/_validations/yard-management/movements/modalBlFormValidations';
import UpperCase from '@/_validations/uppercase-directive';
import { DateFormater } from '@/_helpers/funciones';
import moment from 'moment';

function data() {
  return {
    StatusHistoryList: [],
    StatusList: [],
    previousDate:  DateFormater.formatDateRange(process.env.VUE_APP_START_DATE_IN_PATIO),
    laterDate: '',
    BlCargoStatusHistId: '',
    isSubmitStatus: false,
    isEdit: false
  };
}

//-----------------------   Method    ------------------------

function clearFilters() {
  this.form.status.Status = '';
  this.form.status.TransactionDate = '';
  this.form.status.Motive = '';
  this.BlCargoStatusHistId = '',

  this.$v.$reset();
  this.isEdit = false
}

function EditStatus(item) {
  this.isEdit = true
  this.form.status.Status = item.BlStatusId;
  this.form.status.TransactionDate = moment(item.TransactionDate).format('DD/MM/YYYY');
  this.form.status.Motive =  item.Observation;
  this.BlCargoStatusHistId = item.BlCargoStatusHistId;
}

function getDateRange(){
  this.laterDate = new Date();
}

function getFormatterDateInList(item){
  return item ? DateFormater.formatOnlyDateWithSlash(item) : 'N/A'
}

async function resetForm() {
  //imdg
  this.form.status.Status = '';
  this.form.status.TransactionDate = '';
  this.form.status.Motive = '';
  this.BlCargoStatusHistId = '',

  //data array
  this.StatusHistoryList = [],
  this.StatusList = []
  this.isEdit = false
}

function getService() {
  this.$store.state.yardManagement.loading = true;
  let peticiones = [
    this.$http.ejecutar("GET", "BlCargoStatusHist-list", { BlCargoId: this.ContainerItem.BlCargoId ?? '' }),
    this.$http.ejecutar("GET", "BlCargoStatus-list", {}),
    
  ];
  Promise.all(peticiones)
    .then((responses) => {
      this.StatusHistoryList = responses[0]?.data?.data ?? []
      this.StatusList = responses[1]?.data?.data ?? []
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = false;
    });
}

function submitBodyStatus(){
  try {
    this.$store.state.yardManagement.loading = true;
    this.isSubmitStatus = true;
    this.$v.form.status.$touch();

    if (this.$v.form.status.$error) {
      this.isSubmitStatus = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let BlCargoStatusHistJson = this.isEdit ? 
    {
      BlCargoStatusHistId: this.BlCargoStatusHistId,
      BlCargoId: this.ContainerItem.BlCargoId ?? '',
      BlStatusId: this.form.status.Status,
      Observation: this.form.status.Motive,
      TransactionDate: moment(this.form.status.TransactionDate).format("YYYY-MM-DD")
    }
    :
    {
      BlCargoId: this.ContainerItem.BlCargoId ?? '',
      BlStatusId: this.form.status.Status,
      Observation: this.form.status.Motive,
      TransactionDate: moment(this.form.status.TransactionDate).format("YYYY-MM-DD")
    };

    let metodo = this.isEdit ? 'PUT':'POST';
    let ruta = this.isEdit ? 'BlCargoStatusHist-update': 'BlCargoStatusHist-insert';
    

    this.$http.ejecutar(metodo, ruta, BlCargoStatusHistJson, { root: 'BlCargoStatusHistJson' })
    .then(async ()=> {
        this.clearFilters();
        await this.getService();
        this.isSubmitDocument = false;
        this.$store.state.yardManagement.loading = false;
        this.isEdit = false
        this.isSubmitStatus =  false;
        
      }).catch((err) => {
        this.isSubmitStatus = false;
        this.$store.state.yardManagement.loading = false;
        this.isSubmitStatus =  false;
        this.notifyError({text: err});
      });
  } catch (e) {
    this.isSubmitStatus = false;
    this.$store.state.yardManagement.loading = false;
    this.isSubmitStatus =  false;
    this.notifyError({text: e});
  }
   
}

//-----------------------   Computed   ------------------------
function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

function StatusListOptions() {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  if(this.StatusList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.StatusList.map(function(e){
      chart.push({
        value: e.BlStatusId, 
        label: e[`ValueDs${_lang}`] ?? ''
      })    
    })
    return chart;
  }
}

function statusfields(){
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return [
    { key: 'Nro', label: '#', _style: 'width:3%; text-align:left', _classes: 'text-center', filter: false },
    { key: `BlPreviousStatusDs${_lang}`, label: this.$t('label.previousStatus'),  _classes: 'text-uppercase text-center', _style: 'width:15%;', sorter: true, filter: true},
    { key: `BlStatusDs${_lang}`, label: this.$t('label.newStatus'),  _classes: 'text-uppercase text-center', _style: 'width:15%;', sorter: true, filter: true},
    { key: 'TransactionDate', label: this.$t('label.statusDate'), _classes: 'text-uppercase text-center', _style: 'width:15%;', sorter: true, filter: true},
    { key: 'Observation', label: `${this.$t('label.observation')}`, _style:'width:15%;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
    { key: 'TransaLogin', label: `${this.$t('label.user')}`, _style:'width:15%;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
    { key: 'TransaRegDate', label: `${this.$t('label.date')}`, _style:'width:15%;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
    { key: 'options', label: '', _style: 'width: 1%; min-width:90px; text-align:center;', sorter: false, filter: false },
  ];
}

function computedStatusList() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return this.StatusHistoryList.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      BlPreviousStatusDsEs: item.BlPreviousStatusDsEs ?? '',
      BlPreviousStatusDsEn: item.BlPreviousStatusDsEn ?? '',
      TransaRegDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
    }
  });
}

export default {
  name: 'blStatus-tab',

  props: {
    form: {
      type: Object,
      required: true,
      default: () => {},
    },
    ContainerItem: Object,
    validCargoMaster: Boolean,
    Tab: Boolean,
    showModal: Boolean
  },
  data,
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  validations() {
    return ModalBlFormValidations(this.form.customBroke.consolidatorCheck,this.validCargoMaster);
  },
  methods: {
    resetForm,
    getService,
    getDateRange,
    validateDateRange,
    submitBodyStatus,
    clearFilters,
    EditStatus,
    getFormatterDateInList
  },
  computed:{
    statusfields,
    computedStatusList,
    StatusListOptions,
  },
  watch: {
    showModal: async function (Newval) {
      if (!Newval) {
        this.resetForm();
      }
    },
    Tab: function (Newval) {
      if (Newval) {
        this.clearFilters();
        this.getDateRange();
        this.getService();
      }
    },
  }
};
</script>