<template>
  <div>
    <CRow class="mt-3 justify-content-center">
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.notifyParty')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.notify.NotifyToName.$model"
          :is-valid="hasError(vForm.notify.NotifyToName)"
          :invalid-feedback="errorMessage(vForm.notify.NotifyToName)"
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.address')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.notify.NotifyToAddress.$model"
          :is-valid="hasError(vForm.notify.NotifyToAddress)"
          :invalid-feedback="errorMessage(vForm.notify.NotifyToAddress)"
        >
        </CInput>
      </CCol>
    </CRow>

    <CRow class="justify-content-center">
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.mainPhone')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.notify.NotifyToPrimaryPhone.$model"
          :is-valid="hasError(vForm.notify.NotifyToPrimaryPhone)"
          :invalid-feedback="errorMessage(vForm.notify.NotifyToPrimaryPhone)"
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.secondaryPhone')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.notify.NotifyToSecondaryPhone.$model"
          :is-valid="hasError(vForm.notify.NotifyToSecondaryPhone)"
          :invalid-feedback="errorMessage(vForm.notify.NotifyToSecondaryPhone)"
        >
        </CInput>
      </CCol>
    </CRow>

    <CRow class="justify-content-center">
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.mainEmail')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.notify.NotifyToPrimaryEmail.$model"
          :is-valid="hasError(vForm.notify.NotifyToPrimaryEmail)"
          :invalid-feedback="errorMessage(vForm.notify.NotifyToPrimaryEmail)"
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.secondaryEmail')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.notify.NotifyToSecondaryEmail.$model"
          :is-valid="hasError(vForm.notify.NotifyToSecondaryEmail)"
          :invalid-feedback="errorMessage(vForm.notify.NotifyToSecondaryEmail)"
        >
        </CInput>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';


//-----------------------   Method    ------------------------

async function resetForm() {
  //seller
  this.form.seller.SellerName = '';
  this.form.seller.SellerAddress = '';
  this.form.seller.SellerPrimaryPhone = '';
  this.form.seller.SellerSecondaryPhone =  '';
  this.form.seller.SellerPrimaryEmail = '';
  this.form.seller.SellerSecondaryEmail = '';
}

export default {
  name: 'notifyParty-tab',

  props: {
    form: {
      type: Object,
      required: true,
      default: () => {},
    },
    vForm: Object,
    isEdit: Boolean,
    validCargoMaster: Boolean,
    Tab: Boolean,
    showModal: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  methods: {
    resetForm
  },
  watch: {
    showModal: async function (Newval) {
      if (!Newval) {
        this.resetForm();
      }
    },
  }
};
</script>